import { defineStore } from 'pinia'
import axios from 'axios'
import { showError } from '@/services/axios'
import { apiURL } from '@/main'

export const useLabelTypesStore = defineStore('labelTypesStore', {
  state: () => ({
    LabelTypes: [] as any[]
  }),
  actions: {
    async fetchLabelTypes() {
      if (this.LabelTypes.length == 0) {
        try {
          const result = await axios.get(apiURL + '/labelTypes')
          return await result.data
        } catch (e) {
          showError(e)
        }
      } else {
        return this.LabelTypes
      }
    }
  }
})
